<template>
  <div class="search-engine">
    <div class="wrapper" v-if="results">
      <div class="search-engine-heading">
        <h1 class="site-name">BagoucozDZ</h1>
        <div v-if="results" class="results-stats">{{ results.pagination.total }} résultats</div>
      </div>

        <div class="search-field">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <circle stroke="#cccccc" fill="#ffffff" stroke-width="2" cx="10.5" cy="10.5" r="8.5"></circle>
            <line x1="17.5" y1="17.5" x2="22.5" y2="22.5" id="Line" stroke="#cccccc" stroke-width="2" stroke-linecap="square"></line>
          </svg>
          <input type="text" :value="query" @input="updateQuery" class="search-field-input" placeholder="Rechercher par nom ou immatriculation">
          <svg v-if="query && query.length > 0" class="reset-search-field" @click="() => setQuery('')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ccc"><path d="M0 0h24v24H0z" fill="transparent"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </div>

      <div class="badges">
        <div v-for="filter in selectedFilters" :key="filter.name" class="badge" v-on:click="remove_filter(filter.facet, filter.name)">
          <div class="badge-elements">
            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#999"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
            <span>{{ filter.name }}</span>
          </div>
        </div>
      </div>

      <div class="search-wrapper">
      <div class="search-side" v-if="false">
        <div v-for="(facet, facetId) in results.data.aggregations" :key="facetId">
          <ul class="browse-list long-list" style="margin-bottom: 0;">
            <li v-for="bucket in facet.buckets" :key="bucket.key" v-show="bucket.doc_count > 0">
              <div class="checkbox block" style="margin-top: 0; margin-bottom: 0;">
                <label>
                  <input class="checkbox" type="checkbox" v-model="filters[facet.name]" :value="bucket.key">
                  {{ bucket.key }} ({{ bucket.doc_count }})
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="results-wrapper">
        <table class="results-layout">
          <thead>
            <th>
              immat.
            </th>
            <th>
             Nom
            </th>
            <th>
              Type
            </th>
            <th>
              Patron(s)
            </th>
            <th>
              Dates
            </th>
          </thead>
          <tr class="result-item"
            v-for="(item, i) of results.data.items"
            :key="item.id"
            @click="() => onResultClick(item)"
            :style="'--animation-order:' + i"
          >
            <td class="result-immat">
              {{ item.immat }}
            </td>
            <td class="result-title">
              {{ item.nom }}
            </td>
            <td class="result-type">
              {{ item.type }}
            </td>
            <td class="result-patron">
              {{ (item.patron.length > 40) ? item.patron.substr(0, 36) + '...' : item.patron }}
            </td>
            <td class="result-dates">
              <template v-if="item.init || item.fin">
                {{ (item.init) ? item.init : '?' }} — {{ (item.fin) ? item.fin : '?' }}
              </template>
            </td>
          </tr>
        </table>
        <!-- <div @click="loadMore" class="more">Voir plus</div> -->
        <div class="pager-wrapper">
          <div v-for="pageNumber in pageItems" @click="setPage(pageNumber)" :key="pageNumber" :class="{'pager-item': true, 'pager-active': pageNumber === page}">
            {{ pageNumber }}
          </div>
        </div>
      </div>
      </div>

    </div>
  </div>
</template>

<script>

import itemsjs from 'itemsjs'

var configuration = {
  searchableFields: ['nom', 'immat', 'type'],
  sortings: {
    name_asc: {
      field: 'nom',
      order: 'asc'
    },
  },
  aggregations: {
    /*
    types: {
      title: 'types',
      size: 100
    },
    type: {
      title: 'type',
      size: 10000
    },
    */
  }
}
export default {
  name: 'SearchEngine',
  props: [
    'query',
    'setQuery',
    'page',
    'setPage',
    'db',
    'filters',
    'selectedFilters',
    'sortKeys',
    'sort',
  ],
  data() {
    return {
      engine: null,
      perPage: 100,
      // page: 1,
      // query: '',
      // selectedFilters: [],
    };
  },
  created() {
    this.engine = itemsjs(this.db, configuration);
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    updateQuery(e) {
      this.setQuery(e.target.value);
    },
    onResultClick(item) {
      this.$emit('setcurrentelement', item);
    },
    hash(s) {
      return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
    },

    remove_filter(facet, name) {
      this.filters[facet] = this.filters[facet].filter(v => {
        return v !== name;
      });
    },

    reset() {
      var filters = {};
      Object.keys(configuration.aggregations).map(function(v) {
        filters[v] = [];
      })

      this.filters = filters;
      this.setQuery('');
    },
  },
  computed: {
    pagesNumber() {
      if (!this.results) return null;
      return Math.floor(this.results.pagination.total / this.results.pagination.per_page);
    },
    pageItems() {
      if (!this.results || !this.pagesNumber) return [];
      return [...Array(this.pagesNumber).keys()].slice(1);
    },
    results() {
      if (!this.engine) return null;
      return this.engine.search({
        query: this.query,
        filters: this.filters,
        per_page: this.perPage,
        page: this.page,
        sort: this.sort
      });
    }
  },
}
</script>

<style>
.toggles {
  margin-bottom: 1.5rem;
}
.toggle input[type="checkbox"] {
  position: absolute;
  clip: rect(0 0 0 0);
}

.badges {
  margin-bottom: 1.5rem;
}

.badge:hover {
  cursor: pointer;
}

.toggle {
  display: inline-block;
  margin: 0 .5rem .5rem 0;
}

.toggle .badge {
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  color: #666;
  border-radius: 50px;
  padding: .2rem .5rem;
  display: inline-block;
}

.toggle input[type="checkbox"]:focus + .badge {
  border-color: #ccc;
}
.toggle input[type="checkbox"]:checked + .badge {
  background: #999;
  border-color: #999;
  color: #fff;
}
.text-field {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: .75rem .75rem;
  color: #444;
  width: 100%;
  box-sizing: border-box;
  display: block;
  margin-bottom: 1rem;
}


/**
 * Result.
 */



.result {
  margin-bottom: 1.5rem;
}

.result:hover {
  /*
  cursor: pointer;
  */
  background: #eee;
}

.result-title {
  font-weight: bold;
}



#grid-sizer,
.grid-item {
  width: calc(100% - 20px);
  margin-bottom: 10px;
}


.results-layout .result-item {

  /*
  position: relative;
  display: block;
  margin: 0 0 1rem;
  width: 100%;
  overflow: hidden;

  animation-name: animateIn;
  animation-duration: 300ms;
  animation-delay: calc(var(--animation-order) * 20ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  */
}
.results-layout .result-item:hover {
  /*
  cursor: pointer;
  */
  background: #f6f6f8;
}
.result-item:hover .result-title {
    transform: translateY(0);
    opacity: 1;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
  }
}

#nav .results-layout {
  width: 870px;
}

#nav.active {
  min-height: 100vh;
  overflow: scroll;
}

.pager-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  border-radius: 4px;
  background: #eee;
  overflow:hidden;
}

.pager-item:hover {
  cursor: pointer;
  background: #ddd;
}

.pager-item {
  padding: .75rem;
}
.pager-item.pager-active {
  background: #000;
  color: #fff;
}


.search-field {
  padding: .75rem 1rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  border-radius: 5px;
  border: 1px solid #eee;;
  margin-bottom: 1.5rem;
}
.search-field-input {
  display: block;
  font-size: 1.2rem;
  color: #999;
  border: none;
  flex-grow: 1;
  margin-left: .5rem;
}
.results-stats {
  background: #000;
  color: #fff;
  font-size: .9rem;
  padding: .3rem .75rem;
  border-radius: 3px;
}

.search-engine-heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 1rem 0 1.5rem;
}
.search-engine-heading h1 {
  margin: 0;
  line-height: 1;
}
.reset-search-field:hover {
  cursor: pointer;
}
.reset-search-field {
  width: 24px;
  height: 24px;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-side {
  position: absolute;
  top: 0;
  left: 0;
  /*
  width: 28%;
  */
}
.results-wrapper {
  /*
  margin-left: 30%;
  */
}
.search-wrapper {
  position: relative;
}
table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
td {
  border: none;
  /*
  */
  border-top: 1px solid #eee;
  padding: .5rem;
}
.result-immat {
  /*
  font-family: monospace;
  */
  color: #666;
}
th {
  padding: .5rem;
  text-align: left;
  text-transform: uppercase;
  color: #999;
  font-size: 12px;
}
h1 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 22px;
}
</style>
