<template>
  <div id="app">
    <SearchEngine
      :set-page="setPage"
      v-if="db.length > 0"
      :db="db"
      @setcurrentelement="setCurrentElement"
      :query="query"
      :set-query="setQuery"
      :filters="filters"
      :selected-filters="selectedFilters"
      :sort-keys="sortKeys"
      :sort="sort"
      :page="page"
    />
  </div>
</template>

<script>

import SearchEngine from './components/SearchEngine.vue'
import axios from 'axios'

var configuration = {
  searchableFields: ['nom'],
  sortings: {
    name_asc: {
      field: 'nom',
      order: 'asc'
    },
    name_desc: {
      field: 'nom',
      order: 'desc'
    },
  },
  aggregations: {
    /*
    types: {
      title: 'types',
      size: 100
    },
    type: {
      title: 'type',
      size: 10000
    },
    */
  }
}

export default {
  name: 'App',
  components: {
    SearchEngine,
  },
  data() {
    var filters = {};
    Object.keys(configuration.aggregations).map(function(v) {
      filters[v] = [];
    })

    return {
      db: [],
      openFacets: false,
      query: '',
      filters: filters,
      selectedFilters: [],
      sortKeys: Object.keys(configuration.sortings),
      sort: 'name_asc',
      perPage: 100,
      page: 1,
      currentElement: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    setCurrentElement(item) {
      this.currentElement = item;
    },
    setPage(i) {
      this.page = i;
    },
    setQuery(text) {
      this.query = text;
    },
    async fetchData() {
      var data = await axios.get('/bateaudz.json');
      this.db = data.data;
      // this.db = data.data.filter((item) => geoCodedDocumentIds.includes(item.id));
    },
  },
  watch: {
    query() {
      this.setPage(1);
    },
    filters: {
      handler() {
        this.page = 1;
        this.selectedFilters = [];
        for (const [key, value] of Object.entries(this.filters)) {
          for (const name in value) {
            this.selectedFilters.push({
              name: value[name],
              facet: key
            })
          }
        }
      },
      deep: true,
      // this.search();
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
